<template>
  <div class="card-body table-responsive">
    <div
      class="row justify-content-center"
      v-if="
        $store.getters.can('cs.contratosInternos.asignarTarifarios') &&
        listasForms.tarifarios.length > 0
      "
    >
      <div class="form-group col-md-6">
        <v-select
          :class="[
            $v.form.tarifario.$invalid ? 'is-invalid' : 'is-valid',
            $store.getters.getDarkMode ? 'dark-vselect' : '',
          ]"
          v-model="form.tarifario"
          placeholder="Tarifarios"
          label="nombre"
          class="form-control p-0"
          :options="listasForms.tarifarios"
          :filterable="true"
        ></v-select>
      </div>
      <div class="form-group col-md-2">
        <button
          class="btn btn-primary col-md-4"
          v-if="!$v.form.$invalid"
          @click="create()"
        >
          <i class="fas fa-plus"></i>
        </button>
      </div>
    </div>
    <div v-else class="row justify-content-center">
      <div v-if="!tarifarios" class="callout callout-warning text-muted col-md-6">
        <h5 class="m-0">No hay tarifarios disponibles para ser asignados!</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <table
          class="table table-bordered table-striped table-hover table-sm mb-0"
          v-if="tarifarios && tarifarios.length > 0"
        >
          <thead class="thead-dark">
            <tr>
              <th class="col-3">Nombre</th>
              <th>Descripción</th>
              <th class="col-1">Estado</th>
              <th class="col-1" v-if="estado != 2">Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(tarifario, index) in tarifarios" :key="index">
              <td>{{ tarifario.nombre }}</td>
              <td>{{ tarifario.observaciones }}</td>
              <td class="text-center">
                <span
                  class="badge"
                  :class="
                    tarifario.estado == 1 ? 'badge-warinig' : 'badge-success'
                  "
                >
                  {{ tarifario.nEstado }}
                </span>
              </td>
              <td class="text-right" v-if="estado != 2">
                <div class="btn-group">
                  <button
                    class="btn btn-sm btn-danger"
                    v-if="
                      $store.getters.can(
                        'cs.contratosInternos.asignarTarifarios'
                      )
                    "
                    @click="destroy(index)"
                  >
                    <i class="fas fa-trash"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else class="callout callout-warning text-muted m-0">
          <h5 class="m-0">No hay tarifarios asignados!</h5>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from "axios";
import vSelect from "vue-select";
import { required } from "vuelidate/lib/validators";

export default {
  name: "CsContratoNacionalTarifario",

  components: {
    vSelect,
  },

  data() {
    return {
      estado: null,
      tarifarios: null,
      tarifarios_id: [],
      listasForms: {
        tarifarios: [],
      },
      form: {},
    };
  },

  validations: {
    form: {
      tarifario: {
        required,
      },
    },
  },

  methods: {
    getIndex() {
      this.$parent.cargando = true;
      this.form = {};
      axios
        .get("/api/cs/contratosNacionales/tarifarios/" + this.$parent.id, {})
        .then((response) => {
          this.estado = response.data.estado;
          this.tarifarios = response.data.tarifarios;
          this.tarifarios_id =
            response.data.tarifarios.length > 0
              ? this.tarifarios.map((tarif) => tarif.id)
              : [];
          this.getTarifarios();
          this.$parent.cargando = false;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.cargando = false;
        });
    },

    // Funciones para llenado de las listas
    getTarifarios() {
      axios
        .get("/api/cs/tarifarios/lista", {
          params: {
            not_in: this.tarifarios_id,
          },
        })
        .then((response) => {
          this.listasForms.tarifarios = response.data;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },

    // Funciones para las acciones de los formularios
    create() {
      this.tarifarios_id = [];

      // // Funcionalidad cuando el select de tarifarios es multiselect
      // const tarifarios_nuevos = this.form.tarifario.map((form) => form.id);
      // const tarifarios_actuales =
      //   this.tarifarios.length > 0
      //     ? this.tarifarios.map((tarif) => tarif.id)
      //     : [];
      // this.tarifarios_id = tarifarios_nuevos.concat(tarifarios_actuales);

      // Funcionalidad cuando el select de tarifarios es select único
      this.tarifarios_id = this.form.tarifario.id;
      this.save();
    },

    destroy(index) {
      this.tarifarios.splice(index, 1);
      this.tarifarios_id =
        this.tarifarios.map((tarif) => tarif.id).length > 0
          ? this.tarifarios.map((tarif) => tarif.id)
          : [];
      this.save();
    },

    save() {
      this.$parent.cargando = true;
      axios({
        method: "POST",
        url: "/api/cs/contratosNacionales/tarifarios",
        data: {
          cs_contrato_nacional_id: this.$parent.id,
          cs_tarifario_nacional_id: this.tarifarios_id,
        },
      })
        .then(() => {
          this.$parent.cargando = false;
          this.getIndex();
          this.$swal({
            icon: "success",
            title: "El tarifario se actualizó exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((e) => {
          this.$parent.cargando = false;
          this.getIndex();
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },
  },

  mounted() {
    this.getIndex();
  },
};
</script>
  